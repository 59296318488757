/* eslint-disable no-param-reassign, camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';

import authenticityToken from 'packs/common/authenticityToken';
import Error from 'packs/common/Error';
import Pagination from 'packs/common/Pagination';
import ProgressBar from 'packs/common/ProgressBar';
import getOffset from 'src/common/offset';
import { convertAnswersToState } from './utils';
import FormSection from './FormSection';

const PARTICIPANT_QUESTION_ID = -1;

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    let sectionIndex = 1;
    let answers = {};
    if (this.props.response) {
      const section = this.props.sections.find(sec => (
        sec.id === this.props.response.form_section_id
      ));
      sectionIndex = this.clampSectionIndex(this.props.sections.indexOf(section) + 2);
      answers = convertAnswersToState(this.props.response.answers);
    }

    this.state = {
      saving: false,
      dirty: false,
      answers,
      validationErrors: {},
      serverErrors: [],
      currentSection: sectionIndex,
      response: this.props.response,
    };
  }

  componentDidMount() {
    const { response } = this.props;

    if (response?.user) {
      this.setState(prev => ({
        answers: {
          ...prev.answers,
          [PARTICIPANT_QUESTION_ID]: response.user.id,
        },
      }));
    }
  }

  get sections() {
    const {
      sections,
      allow_spouse_completion,
      possible_participants,
      response,
    } = this.props;

    if (allow_spouse_completion) {
      let participantOptions;

      if (response?.user) {
        participantOptions = [{
          id: response.user.id,
          label: response.user.name,
          icon: null,
          position: 0,
          weighted_value: 0,
        }];
      } else {
        participantOptions = possible_participants.map(participant => ({
          id: participant.id,
          label: participant.name,
          icon: null,
          position: 0,
          weighted_value: 0,
        }));
      }

      const participantSection = {
        id: PARTICIPANT_QUESTION_ID,
        title: 'Participant',
        description: '',
        color: '',
        icon: null,
        position: 0,
        questions: [
          {
            id: PARTICIPANT_QUESTION_ID,
            label: 'Who is this submission for?',
            required: true,
            disabled: !!response?.user,
            kind: 'select',
            position: 0,
            options: participantOptions,
          },
        ],
      };

      return [
        participantSection,
        ...sections,
      ];
    }

    return sections;
  }

  clampSectionIndex = index => (
    index > this.sections.length ? this.sections.length : index);

  handleQuestionChange = (questionId, value) => {
    const state = { ...this.state };
    if (this.isValueValid(value)) {
      state.answers[questionId] = value;
    } else {
      delete state.answers[questionId];
    }
    if (this.findQuestion(questionId).required) {
      this.validateQuestion(state, questionId);
    }
    state.dirty = true;
    this.setState(state);
  };

  findQuestion = (id) => {
    const predicate = question => question.id.toString() === id.toString();
    const section = this.sections.find(sec => sec.questions.find(predicate));

    if (section) {
      return section.questions.find(predicate);
    }

    return null;
  };

  validateSection = (state, section) => {
    section.questions.filter(q => q.required).forEach((question) => {
      this.validateQuestion(state, question.id);
    });

    const keys = Object.keys(this.state.validationErrors);
    if (keys.length > 0) {
      this.scrollToError(keys[0]);
    }

    return state.validationErrors;
  };

  validateQuestion = (state, questionId) => {
    if (this.isValueValid(state.answers[questionId])) {
      delete state.validationErrors[questionId];
    } else {
      state.validationErrors[questionId] = 'This field is required';
    }
  };

  isValueValid = (value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return !!value;
  };

  scrollToError = (questionId) => {
    const elementByName = document.getElementsByName(`question_${questionId}`)[0];
    const elementById = document.getElementById(`question_${questionId}`);
    let offset;

    if (elementById) {
      offset = getOffset(elementById);
    } else if (elementByName) {
      offset = getOffset(elementByName);
    }
    window.scrollTo({ top: (offset.top - 200), behavior: 'smooth' });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState(prevState => ({
      validationErrors: this.validateSection(prevState, this.currentSection()),
    }), () => {
      if (this.isValid()) {
        this.submitAnswers(this.currentSectionAnswers(), this.onComplete);
      }
    });
  };

  onComplete = (response) => {
    if (this.props.onComplete) {
      this.props.onComplete(response);
    } else {
      window.location = this.props.redirect || response.data.url;
    }
  };

  submitAnswers = (answers, callback) => {
    const { answers: stateAnswers, saving } = this.state;
    const hasRealAnswer = Object.keys(stateAnswers).some(answerId => answerId !== PARTICIPANT_QUESTION_ID.toString());

    if (hasRealAnswer && !saving) {
      this.setState({ saving: true });
      axios({
        method: this.state.response ? 'patch' : 'post',
        url: this.submitUrl(),
        data: this.formData(answers),
      }).then(callback).catch((error) => {
        if (error.response.headers['content-type'].includes('application/json')) {
          this.setState({ serverErrors: error.response.data.messages, saving: false });
        } else if (error.response.status === 413) {
          this.setState({ serverErrors: ['Your file upload(s) are too large. Please reduce file sizes and try again, or contact your administrator.'], saving: false });
        } else {
          this.setState({ serverErrors: ['Please contact your administrator'], saving: false });
        }
      });
    } else if (!saving) {
      callback();
    }
  }

  formData = (answers) => {
    const { response, answers: stateAnswers } = this.state;
    const formData = new FormData();
    formData.append('authenticity_token', authenticityToken());
    formData.append('form_response[form_section_id]', this.currentSection().id);

    if (this.props.hideFlash) {
      formData.append('hide_flash', true);
    }

    if (!response && this.props.timestamp) {
      formData.append('form_response[timestamp]', this.props.timestamp);
    }

    if (stateAnswers[PARTICIPANT_QUESTION_ID]) {
      formData.append('form_response[user_id]', stateAnswers[PARTICIPANT_QUESTION_ID]);
    }

    Object.keys(answers).forEach((key) => {
      const prefix = `form_response[answers_attributes][${key}]`;
      formData.append(`${prefix}[question_id]`, key);

      if (response) {
        const answer = response.answers.find(item => (
          item.question.id.toString() === key.toString()
        ));

        if (answer) {
          formData.append(`${prefix}[id]`, answer.id);
        }
      }

      let answer = stateAnswers[key];
      const question = this.findQuestion(key);
      if ('options' in question) {
        if (!Array.isArray(answer)) {
          answer = [answer];
        }
        answer.forEach((optionId) => {
          formData.append(`${prefix}[question_option_ids][]`, optionId);
        });
      } else if (question.kind === 'attachment') {
        formData.append(`${prefix}[attachment]`, answer);
      } else {
        formData.append(`${prefix}[response]`, answer);
      }
    });
    return formData;
  };

  savingButtonText = (buttonText = 'Continue') => {
    if (this.state.saving) {
      return (
        <span>
          <i className="fa fa-circle-o-notch fa-spin fa-fw" />
          <span>Saving</span>
        </span>
      );
    }
    return (
      <span>
        <span>{buttonText}</span> <i className="fa fa-angle-right" />
      </span>
    );
  };

  errorMessage = () => {
    if (this.state.serverErrors.length > 0) {
      return (
        <Error message="An error has occurred">
          {this.state.serverErrors.map(error => (
            <p key={error} className="text-center">
              <strong>{error}</strong>
            </p>
          ))}
        </Error>
      );
    }

    return null;
  };

  handleSectionChange = (page) => {
    if (page === 1 || page <= this.state.currentSection) {
      this.changePage(page);
    } else {
      this.setState(prevState => ({
        validationErrors: this.validateSection(prevState, this.currentSection()),
      }), () => {
        if (this.isValid()) {
          if (this.state.dirty) {
            this.submitAnswers(this.currentSectionAnswers(), (response) => {
              if (response) {
                this.setState({ response: response.data, dirty: false, saving: false });
              }
              this.changePage(page);
            });
          } else {
            this.changePage(page);
          }
        }
      });
    }
  };

  changePage = (page) => {
    this.setState({ currentSection: page, validationErrors: {}, dirty: false }, () => {
      if (!this.props.quiz) {
        window.scrollTo(0, 0);
      }
    });
  };

  isLastSection = () => this.state.currentSection === this.sections.length;

  currentSectionAnswers() {
    return this.currentSection().questions.reduce((answers, question) => {
      const answer = this.state.answers[question.id];
      if (answer) answers[question.id] = this.state.answers[question.id];
      return answers;
    }, {});
  }

  currentSection() {
    return this.sections[this.state.currentSection - 1];
  }

  submitUrl() {
    return this.state.response ? this.state.response.url : this.props.url;
  }

  isValid() {
    return Object.keys(this.state.validationErrors).length === 0 &&
      this.state.validationErrors.constructor === Object;
  }

  progressPercentage() {
    return Math.round(((this.state.currentSection - 1) / this.sections.length) * 100);
  }

  progressBar() {
    if (this.sections.length > 1) {
      return <ProgressBar progress={this.progressPercentage()} />;
    }
    return null;
  }

  render() {
    return (
      <div className="card card-stacked">
        <header className="card-header">
          <h2 className="header-title">{this.props.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
        </header>

        <FormSection
          {...this.currentSection()}
          onQuestionChange={this.handleQuestionChange}
          validationErrors={this.state.validationErrors}
          answers={this.state.answers}
          progressBar={this.progressBar()}
        />

        {this.errorMessage()}

        <div className="card-footer text-center">
          <Pagination
            onPageChange={this.handleSectionChange}
            totalCount={this.sections.length}
            perPage={1}
            currentPage={this.state.currentSection}
            showFirstLast={false}
            showPageCount={false}
            prevText={(
              <span>
                <i className="fa fa-angle-left" /> Previous
              </span>
            )}
            nextText={this.savingButtonText()}
            prevButtonClass="btn-secondary margin-right"
            nextButtonClass={classNames('btn-primary', 'margin-left', { 'd-none': this.isLastSection(), disabled: this.state.saving })}
          />
          <button
            type="submit"
            className={classNames('btn', 'btn-primary', { 'd-none': !this.isLastSection(), disabled: this.state.saving })}
            disabled={!this.isValid()}
            onClick={this.handleSubmit}
          >
            {this.savingButtonText('Submit')}
          </button>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  url: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  sections: PropTypes.array.isRequired,
  timestamp: PropTypes.string,
  response: PropTypes.object,
  onComplete: PropTypes.func,
  redirect: PropTypes.string,
  quiz: PropTypes.bool,
  hideFlash: PropTypes.bool,
  allow_spouse_completion: PropTypes.bool,
  possible_participants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
  })),
};

Form.defaultProps = {
  description: undefined,
  response: undefined,
  timestamp: undefined,
  redirect: undefined,
  onComplete: undefined,
  quiz: false,
  hideFlash: false,
  allow_spouse_completion: false,
  possible_participants: [],
};
