import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Label = props => (
  <label className={classNames(props.className)} htmlFor={props.name}>
    <span>{props.children}</span>
    <span>{props.text}</span>
    {props.required || <span className="margin-left-sm text-muted"><span>(Optional)</span></span>}
  </label>
);

export default Label;

Label.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
};

Label.defaultProps = {
  className: '',
  children: null,
};
