import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from '../Label';
import Radio from './Radio';

export default class RadioQuestion extends React.Component {
  handleInputChange = (value) => {
    this.props.onChange(this.props.id, parseInt(value));
  }

  render() {
    return (
      <div className={classNames('form-group', { 'has-error': this.props.error })}>
        <Label
          name={this.props.name}
          text={this.props.label}
          required={this.props.required}
        />
        <div className="radio-wrapper">
          {this.props.options.map(option => (
            <Radio
              {...option}
              key={option.id}
              error={this.props.error}
              questionId={this.props.id}
              name={this.props.name}
              checked={this.props.value === option.id}
              onChange={this.handleInputChange}
              required={this.props.required}
              disabled={this.props.disabled}
              quiz={this.props.quiz}
            />
          ))}
        </div>
        <span className="invalid-feedback d-block"><span>{this.props.error}</span></span>
      </div>
    );
  }
}

RadioQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.number,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  quiz: PropTypes.bool,
};

RadioQuestion.defaultProps = {
  error: '',
  value: undefined,
  required: false,
  disabled: false,
  quiz: false,
};
