import React from 'react';
import PropTypes from 'prop-types';

import TextQuestion from './inputs/TextQuestion';
import DateQuestion from './inputs/DateQuestion';
import SelectQuestion from './inputs/SelectQuestion';
import MultiSelectQuestion from './inputs/MultiSelectQuestion';
import TextAreaQuestion from './inputs/TextAreaQuestion';
import CheckBoxQuestion from './inputs/CheckBoxQuestion';
import RadioQuestion from './inputs/RadioQuestion';
import AttachmentQuestion from './inputs/AttachmentQuestion';

const Question = (props) => {
  switch (props.type) {
    case 'text_field': return <TextQuestion {...props} />;
    case 'date': return <DateQuestion {...props} />;
    case 'text_area': return <TextAreaQuestion {...props} />;
    case 'select': return <SelectQuestion {...props} />;
    case 'multiselect': return <MultiSelectQuestion {...props} />;
    case 'check_box': return <CheckBoxQuestion {...props} />;
    case 'radio_button': return <RadioQuestion {...props} />;
    case 'attachment': return <AttachmentQuestion {...props} />;
    default: return null;
  }
};

export default Question;

Question.propTypes = {
  type: PropTypes.string.isRequired,
};
