import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = (props) => {
  const text = props.text ? props.text : `${Math.round(props.progress)}%`;
  const style = { width: `${props.progress}%` };
  return (
    <div className="lh-progress lh-progress--flip">
      <span
        className="lh-progress-label"
        data-day={text}
        style={style}
      />
    </div>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  text: PropTypes.string,
  progress: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  text: undefined,
};
