import React from 'react';
import PropTypes from 'prop-types';

const FormDescription = (props) => {
  const dividerStyle = {};

  if (props.color) {
    dividerStyle.borderTopColor = props.color;
  }

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: `<span>${props.text}</span>` }} />
      <hr className="form-section-divider" style={dividerStyle} />
    </div>
  );
};

export default FormDescription;

FormDescription.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

FormDescription.defaultProps = {
  color: undefined,
};
