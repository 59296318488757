import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Radio.scss';

export default class Radio extends React.Component {
  handleInputChange = (event) => {
    this.props.onChange(event.target.value);
  }

  id() {
    return `${this.props.name}_${this.props.id}`;
  }

  isGraphical() {
    return !!this.props.icon;
  }

  answerFeedback() {
    if (!this.props.quiz || !this.props.disabled) {
      return null;
    }
    if (this.props.weighted_value > 0) {
      return (
        <small className="ms-4 text-success"><i className="fa fa-check" aria-hidden="true" />
          <span>{this.props.checked && ' You got it!'}</span>
        </small>
      );
    }
    if (this.props.checked && this.props.weighted_value === 0) {
      return (
        <small className="ms-4 text-danger"><i className="fa fa-times" aria-hidden="true" /><span> Oops, try again.</span></small>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={classNames('form-check radio', { 'graphical-radio': this.isGraphical() }, { disabled: this.props.disabled })}>
        <input
          type="radio"
          id={this.id()}
          className={classNames('form-check-input', { 'is-invalid': this.props.error })}
          name={this.props.name}
          onChange={this.handleInputChange}
          value={this.props.id}
          checked={this.props.checked}
          required={this.props.required}
          disabled={this.props.disabled}
        />
        <label htmlFor={this.id()} className={classNames('form-check-label', { 'graphical-radio__label': this.isGraphical() })}>
          {this.isGraphical() && <img src={this.props.icon} className="graphical-radio__image form-check-label" alt={this.props.label} width={80} height={80} />}
          <span>{this.props.label}</span>
        </label>
        {this.answerFeedback()}
      </div>
    );
  }
}

Radio.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  icon: PropTypes.string,
  weighted_value: PropTypes.number,
  quiz: PropTypes.bool,
};

Radio.defaultProps = {
  error: '',
  required: false,
  disabled: false,
  checked: false,
  icon: undefined,
  weighted_value: 0,
  quiz: false,
};
