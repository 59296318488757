interface Offset {
  top: number;
  left: number;
}
export default function getOffset(element: HTMLElement | null) : Offset {
  let x = 0;
  let y = 0;
  let el : HTMLElement | null = element;
  while (el && !Number.isNaN(el.offsetLeft) && !Number.isNaN(el.offsetTop)) {
    x += el.offsetLeft - el.scrollLeft;
    y += el.offsetTop - el.scrollTop;
    el = el.offsetParent as HTMLElement;
  }
  return { top: y, left: x };
}
