import React from 'react';
import PropTypes from 'prop-types';

const Error = props => (
  <div className="alert alert-danger text-center margin-all">
    <h4>{props.message}</h4>
    {props.children}
  </div>
);

export default Error;

Error.propTypes = {
  message: PropTypes.string,
  children: PropTypes.element,
};

Error.defaultProps = {
  message: 'Oops! Something happened',
  children: null,
};
