import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from '../Label';

export default class AttachmentQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: typeof props.value === 'object' ? props.value.name : props.label };
  }

  handleInputChange = (event) => {
    const fileName = event.target.files[0] ? event.target.files[0].name : this.props.label;
    this.setState({ value: fileName });
    this.props.onChange(this.props.id, event.target.files[0]);
  }

  renderAttachment() {
    return <a href={this.props.value}><span>View uploaded attachment</span></a>;
  }

  renderReadOnly() {
    if (this.props.value) {
      return this.renderAttachment();
    }
    return <span>No attachment uploaded</span>;
  }

  renderInput() {
    return (
      <div className="input-group">
        <input
          id={this.props.name}
          type="file"
          className={classNames('form-control', 'rounded-end', { 'is-invalid': this.props.error })}
          name={this.props.name}
          onChange={this.handleInputChange}
          required={this.props.required}
          disabled={this.props.disabled}
        />
        <span>{this.props.value && typeof this.props.value === 'string' ? this.renderAttachment() : ''}</span>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Label
          name={this.props.name}
          text={this.state.value}
          required={this.props.required}
        />
        <div className={classNames('form-group', { 'has-error': this.props.error })}>
          {this.props.disabled ? this.renderReadOnly() : this.renderInput()}
          <span className="invalid-feedback"><span>{this.props.error}</span></span>
        </div>
      </div>
    );
  }
}

AttachmentQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

AttachmentQuestion.defaultProps = {
  error: '',
  required: false,
  disabled: false,
  value: '',
};
