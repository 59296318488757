import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from '../Label';

export default class TextAreaQuestion extends React.Component {
  handleInputChange = (event) => {
    this.props.onChange(this.props.id, event.target.value);
  }

  render() {
    return (
      <div className={classNames('form-group', { 'has-error': this.props.error })}>
        <Label
          name={this.props.name}
          text={this.props.label}
          required={this.props.required}
        />
        <textarea
          id={this.props.name}
          type="text"
          className={classNames('form-control', { 'is-invalid': this.props.error })}
          name={this.props.name}
          onChange={this.handleInputChange}
          required={this.props.required}
          disabled={this.props.disabled}
          value={this.props.value}
        />
        <span className="invalid-feedback"><span>{this.props.error}</span></span>
      </div>
    );
  }
}

TextAreaQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextAreaQuestion.defaultProps = {
  value: '',
  error: '',
  required: false,
  disabled: false,
};
