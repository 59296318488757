import React from 'react';
import PropTypes from 'prop-types';

import Question from './Question';
import FormDescription from './FormDescription';

export default class FormSection extends React.Component {
  titleStyle() {
    if (this.props.color) {
      return { backgroundColor: this.props.color };
    }
    return { position: 'relative' };
  }

  render() {
    return (
      <section>
        {this.props.title && (
          <h6 className="challenge-title" style={this.titleStyle()}>
            {this.props.icon && (
              <span className="po8-icon">
                <img
                  src={this.props.icon}
                  width={20}
                  height={20}
                  alt=""
                />
              </span>
            )}
            <span>{this.props.title}</span>
          </h6>
        )}

        {this.props.progressBar}

        <form className="modal-body clearfix needs-validation" noValidate>
          {this.props.description && (
            <FormDescription
              text={this.props.description}
              color={this.props.color}
            />
          )}

          {this.props.questions.map(question => (
            <fieldset key={question.id}>
              <Question
                {...question}
                type={question.kind}
                name={`question_${question.id}`}
                value={this.props.answers[question.id]}
                error={this.props.validationErrors[question.id]}
                onChange={this.props.onQuestionChange}
              />
            </fieldset>
          ))}
        </form>
      </section>
    );
  }
}

FormSection.propTypes = {
  questions: PropTypes.array.isRequired,
  validationErrors: PropTypes.object.isRequired,
  onQuestionChange: PropTypes.func.isRequired,
  answers: PropTypes.object.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  progressBar: PropTypes.element,
};

FormSection.defaultProps = {
  title: undefined,
  color: undefined,
  icon: undefined,
  description: undefined,
  progressBar: undefined,
};
