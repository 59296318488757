import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from '../Label';

export default class SelectQuestion extends React.Component {
  handleInputChange = (event) => {
    this.props.onChange(this.props.id, parseInt(event.target.value));
  };

  render() {
    return (
      <div className={classNames('form-group', { 'has-error': this.props.error })}>
        <Label
          name={this.props.name}
          text={this.props.label}
          required={this.props.required}
        />
        <select
          id={this.props.name}
          className={classNames('form-control', { 'is-invalid': this.props.error })}
          name={this.props.name}
          required={this.props.required}
          onChange={this.handleInputChange}
          disabled={this.props.disabled}
          value={this.props.value}
        >
          <option>-- Select --</option>
          {this.props.options.map(opt => (
            <option key={opt.id} value={opt.id}>{opt.label}</option>))}
        </select>
        <span className="invalid-feedback"><span>{this.props.error}</span></span>
      </div>
    );
  }
}

SelectQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.number,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

SelectQuestion.defaultProps = {
  value: undefined,
  error: '',
  required: false,
  disabled: false,
};
