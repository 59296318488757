/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from '../Label';
import './MultiSelectQuestion.scss';

export default class MultiSelectQuestion extends React.Component {
  handleSelectOption = (id) => {
    if (this.props.disabled) {
      return false;
    }

    const values = new Set(this.props.value);

    if (values.has(id)) {
      values.delete(id);
    } else {
      values.add(id);
    }

    this.props.onChange(this.props.id, Array.from(values));

    return true;
  }

  render() {
    return (
      <div className={classNames('form-group', { 'has-error': this.props.error })}>
        <Label
          name={this.props.name}
          text={this.props.label}
          required={this.props.required}
        />
        <div
          id={this.props.name}
          className="multiselect"
        >
          {this.props.options.map(opt => (
            <div
              role="button"
              tabIndex={this.props.disabled || 0}
              disabled={this.props.disabled}
              key={opt.id}
              onClick={() => this.handleSelectOption(opt.id)}
              className={classNames('multiselect__item', { 'multiselect__item--noimage d-flex': !opt.icon }, { disabled: this.props.disabled }, { 'is-invalid': this.props.error })}
            >
              {opt.icon && <img className="multiselect__image" src={opt.icon} alt={opt.label} width={80} height={80} />}
              <div className={classNames('multiselect__check', 'fa', 'fa-check', { selected: this.props.value.indexOf(opt.id) !== -1 })} />
              <div className={classNames('multiselect__label', { selected: this.props.value.indexOf(opt.id) !== -1 })}>
                <span>{opt.label}</span>
              </div>
            </div>
          ))}
        </div>
        <span className="invalid-feedback d-block"><span>{this.props.error}</span></span>
      </div>
    );
  }
}

MultiSelectQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

MultiSelectQuestion.defaultProps = {
  value: [],
  error: '',
  required: false,
  disabled: false,
};
