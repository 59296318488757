import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from '../Label';

export default class CheckBoxQuestion extends React.Component {
  handleInputChange = (event) => {
    this.props.onChange(this.props.id, event.target.checked);
  }

  render() {
    return (
      <div className={classNames('form-group checkbox-group', { 'has-error': this.props.error })}>
        <div className={classNames('checkbox checkbox-fancy', { disabled: this.props.disabled })}>
          <Label
            name={this.props.name}
            text={this.props.label}
            required={this.props.required}
            className="w-100"
          >
            <input
              id={this.props.name}
              type="checkbox"
              name={this.props.name}
              onChange={this.handleInputChange}
              required={this.props.required}
              disabled={this.props.disabled}
              checked={this.props.value}
            />
            <div className={classNames('checkmark', { 'is-invalid': this.props.error })}>
              <i className="fa fa-check fa-lg" />
            </div>
          </Label>
        </div>
        <span className="invalid-feedback d-block"><span>{this.props.error}</span></span>
      </div>
    );
  }
}

CheckBoxQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

CheckBoxQuestion.defaultProps = {
  value: false,
  error: '',
  required: false,
  disabled: false,
};
