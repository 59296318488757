/* eslint-disable react/state-in-constructor */
import React from 'react';
import Flatpickr from 'react-flatpickr';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import Label from '../Label';

export default class DateQuestion extends React.Component {
  state = {
    date: '',
  };

  handleDateSelection = (date) => {
    this.setState({ date });
    this.props.onChange(this.props.id, date);
  };

  render() {
    return (
      <div className={classNames('form-group', { 'has-error': this.props.error })}>
        <Label
          name={this.props.name}
          text={this.props.label}
          required={this.props.required}
        />
        {this.props.disabled ? (
          <input
            className="form-control"
            id={this.props.name}
            type="text"
            name={this.props.name}
            value={this.props.value}
            disabled
          />
        ) : (
          <Flatpickr
            id={this.props.name}
            name={this.props.name}
            className={classNames('form-control', { 'is-invalid': this.props.error })}
            required={this.props.required}
            options={{ allowInput: true, maxDate: this.props.maxDate }}
            value={this.state.date}
            onOpen={() => this.handleDateSelection('')}
            onChange={(selectedDates) => { this.handleDateSelection(moment(selectedDates[0]).format('YYYY-MM-DD')); }}
          />
        )}
        <span className="invalid-feedback"><span>{this.props.error}</span></span>
      </div>
    );
  }
}

DateQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  maxDate: PropTypes.string,
};

DateQuestion.defaultProps = {
  value: '',
  error: '',
  required: false,
  disabled: false,
  maxDate: moment().format('YYYY-MM-DD'),
};
