export function getResponseFromAnswer(answer) {
  let { response } = answer;
  if (answer.choices.length > 0) {
    response = answer.choices.map(choice => choice.question_option_id);
    if (answer.question.kind === 'select' || answer.question.kind === 'radio_button') {
      response = response[0];
    }
  } else if (answer.question.kind === 'check_box') {
    response = response === 'true';
  }
  return response;
}

export function convertAnswersToState(answers) {
  return answers.reduce((convertedAnswers, answer) => ({
    ...convertedAnswers,
    [answer.question.id]: getResponseFromAnswer(answer),
  }), {});
}
